exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-company-tsx": () => import("./../../../src/pages/about/company.tsx" /* webpackChunkName: "component---src-pages-about-company-tsx" */),
  "component---src-pages-about-news-index-tsx": () => import("./../../../src/pages/about/news/index.tsx" /* webpackChunkName: "component---src-pages-about-news-index-tsx" */),
  "component---src-pages-about-news-mdx-frontmatter-news-slug-tsx-content-file-path-src-content-mdx-company-mdx": () => import("./../../../src/pages/about/news/{mdx.frontmatter__news_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/company.mdx" /* webpackChunkName: "component---src-pages-about-news-mdx-frontmatter-news-slug-tsx-content-file-path-src-content-mdx-company-mdx" */),
  "component---src-pages-about-news-mdx-frontmatter-news-slug-tsx-content-file-path-src-content-mdx-news-2018-10-30-cyprus-fintech-hackaton-mdx": () => import("./../../../src/pages/about/news/{mdx.frontmatter__news_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/news/2018-10-30-cyprus-fintech-hackaton.mdx" /* webpackChunkName: "component---src-pages-about-news-mdx-frontmatter-news-slug-tsx-content-file-path-src-content-mdx-news-2018-10-30-cyprus-fintech-hackaton-mdx" */),
  "component---src-pages-about-news-mdx-frontmatter-news-slug-tsx-content-file-path-src-content-mdx-news-2020-10-01-swiss-fintech-innovations-dlt-mdx": () => import("./../../../src/pages/about/news/{mdx.frontmatter__news_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/news/2020-10-01-swiss-fintech-innovations-dlt.mdx" /* webpackChunkName: "component---src-pages-about-news-mdx-frontmatter-news-slug-tsx-content-file-path-src-content-mdx-news-2020-10-01-swiss-fintech-innovations-dlt-mdx" */),
  "component---src-pages-about-news-mdx-frontmatter-news-slug-tsx-content-file-path-src-content-mdx-news-2021-01-28-trustwise-certificates-mdx": () => import("./../../../src/pages/about/news/{mdx.frontmatter__news_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/news/2021-01-28-trustwise-certificates.mdx" /* webpackChunkName: "component---src-pages-about-news-mdx-frontmatter-news-slug-tsx-content-file-path-src-content-mdx-news-2021-01-28-trustwise-certificates-mdx" */),
  "component---src-pages-about-news-mdx-frontmatter-news-slug-tsx-content-file-path-src-content-mdx-news-2021-03-16-blockchain-governance-mdx": () => import("./../../../src/pages/about/news/{mdx.frontmatter__news_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/news/2021-03-16-blockchain-governance.mdx" /* webpackChunkName: "component---src-pages-about-news-mdx-frontmatter-news-slug-tsx-content-file-path-src-content-mdx-news-2021-03-16-blockchain-governance-mdx" */),
  "component---src-pages-about-news-mdx-frontmatter-news-slug-tsx-content-file-path-src-content-mdx-news-2021-03-23-blockchain-study-mdx": () => import("./../../../src/pages/about/news/{mdx.frontmatter__news_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/news/2021-03-23-blockchain-study.mdx" /* webpackChunkName: "component---src-pages-about-news-mdx-frontmatter-news-slug-tsx-content-file-path-src-content-mdx-news-2021-03-23-blockchain-study-mdx" */),
  "component---src-pages-about-news-mdx-frontmatter-news-slug-tsx-content-file-path-src-content-mdx-news-2021-04-01-sfti-attests-trustwise-mdx": () => import("./../../../src/pages/about/news/{mdx.frontmatter__news_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/news/2021-04-01-sfti-attests-trustwise.mdx" /* webpackChunkName: "component---src-pages-about-news-mdx-frontmatter-news-slug-tsx-content-file-path-src-content-mdx-news-2021-04-01-sfti-attests-trustwise-mdx" */),
  "component---src-pages-about-news-mdx-frontmatter-news-slug-tsx-content-file-path-src-content-mdx-news-2021-09-27-augentic-trustwise-partnership-mdx": () => import("./../../../src/pages/about/news/{mdx.frontmatter__news_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/news/2021-09-27-augentic-trustwise-partnership.mdx" /* webpackChunkName: "component---src-pages-about-news-mdx-frontmatter-news-slug-tsx-content-file-path-src-content-mdx-news-2021-09-27-augentic-trustwise-partnership-mdx" */),
  "component---src-pages-about-news-mdx-frontmatter-news-slug-tsx-content-file-path-src-content-mdx-news-2021-10-04-smart-banknote-cbdc-mdx": () => import("./../../../src/pages/about/news/{mdx.frontmatter__news_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/news/2021-10-04-smart-banknote-cbdc.mdx" /* webpackChunkName: "component---src-pages-about-news-mdx-frontmatter-news-slug-tsx-content-file-path-src-content-mdx-news-2021-10-04-smart-banknote-cbdc-mdx" */),
  "component---src-pages-about-news-mdx-frontmatter-news-slug-tsx-content-file-path-src-content-mdx-news-2021-10-12-ofs-augentic-smart-banknote-mdx": () => import("./../../../src/pages/about/news/{mdx.frontmatter__news_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/news/2021-10-12-ofs-augentic-smart-banknote.mdx" /* webpackChunkName: "component---src-pages-about-news-mdx-frontmatter-news-slug-tsx-content-file-path-src-content-mdx-news-2021-10-12-ofs-augentic-smart-banknote-mdx" */),
  "component---src-pages-about-news-mdx-frontmatter-news-slug-tsx-content-file-path-src-content-mdx-news-2021-10-14-kellerhals-carrard-partnership-mdx": () => import("./../../../src/pages/about/news/{mdx.frontmatter__news_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/news/2021-10-14-kellerhals-carrard-partnership.mdx" /* webpackChunkName: "component---src-pages-about-news-mdx-frontmatter-news-slug-tsx-content-file-path-src-content-mdx-news-2021-10-14-kellerhals-carrard-partnership-mdx" */),
  "component---src-pages-about-news-mdx-frontmatter-news-slug-tsx-content-file-path-src-content-mdx-news-2021-10-25-startups-ch-mdx": () => import("./../../../src/pages/about/news/{mdx.frontmatter__news_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/news/2021-10-25-startups-ch.mdx" /* webpackChunkName: "component---src-pages-about-news-mdx-frontmatter-news-slug-tsx-content-file-path-src-content-mdx-news-2021-10-25-startups-ch-mdx" */),
  "component---src-pages-about-news-mdx-frontmatter-news-slug-tsx-content-file-path-src-content-mdx-news-2021-10-29-trustwise-capital-mdx": () => import("./../../../src/pages/about/news/{mdx.frontmatter__news_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/news/2021-10-29-trustwise-capital.mdx" /* webpackChunkName: "component---src-pages-about-news-mdx-frontmatter-news-slug-tsx-content-file-path-src-content-mdx-news-2021-10-29-trustwise-capital-mdx" */),
  "component---src-pages-about-news-mdx-frontmatter-news-slug-tsx-content-file-path-src-content-mdx-news-2022-06-11-emea-mdx": () => import("./../../../src/pages/about/news/{mdx.frontmatter__news_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/news/2022-06-11-emea.mdx" /* webpackChunkName: "component---src-pages-about-news-mdx-frontmatter-news-slug-tsx-content-file-path-src-content-mdx-news-2022-06-11-emea-mdx" */),
  "component---src-pages-about-news-mdx-frontmatter-news-slug-tsx-content-file-path-src-content-mdx-news-2022-06-23-hoffmann-and-partner-mdx": () => import("./../../../src/pages/about/news/{mdx.frontmatter__news_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/news/2022-06-23-hoffmann-and-partner.mdx" /* webpackChunkName: "component---src-pages-about-news-mdx-frontmatter-news-slug-tsx-content-file-path-src-content-mdx-news-2022-06-23-hoffmann-and-partner-mdx" */),
  "component---src-pages-about-news-mdx-frontmatter-news-slug-tsx-content-file-path-src-content-mdx-news-2022-08-09-swiss-fintech-mdx": () => import("./../../../src/pages/about/news/{mdx.frontmatter__news_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/news/2022-08-09-swiss-fintech.mdx" /* webpackChunkName: "component---src-pages-about-news-mdx-frontmatter-news-slug-tsx-content-file-path-src-content-mdx-news-2022-08-09-swiss-fintech-mdx" */),
  "component---src-pages-about-news-mdx-frontmatter-news-slug-tsx-content-file-path-src-content-mdx-news-2022-09-05-purapipe-mdx": () => import("./../../../src/pages/about/news/{mdx.frontmatter__news_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/news/2022-09-05-purapipe.mdx" /* webpackChunkName: "component---src-pages-about-news-mdx-frontmatter-news-slug-tsx-content-file-path-src-content-mdx-news-2022-09-05-purapipe-mdx" */),
  "component---src-pages-about-news-mdx-frontmatter-news-slug-tsx-content-file-path-src-content-mdx-news-2023-09-05-cbdc-conference-mdx": () => import("./../../../src/pages/about/news/{mdx.frontmatter__news_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/news/2023-09-05-cbdc-conference.mdx" /* webpackChunkName: "component---src-pages-about-news-mdx-frontmatter-news-slug-tsx-content-file-path-src-content-mdx-news-2023-09-05-cbdc-conference-mdx" */),
  "component---src-pages-about-news-mdx-frontmatter-news-slug-tsx-content-file-path-src-content-mdx-news-2023-09-05-swiss-fintech-fair-mdx": () => import("./../../../src/pages/about/news/{mdx.frontmatter__news_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/news/2023-09-05-swiss-fintech-fair.mdx" /* webpackChunkName: "component---src-pages-about-news-mdx-frontmatter-news-slug-tsx-content-file-path-src-content-mdx-news-2023-09-05-swiss-fintech-fair-mdx" */),
  "component---src-pages-about-news-mdx-frontmatter-news-slug-tsx-content-file-path-src-content-mdx-news-2023-12-05-cop-28-tech-sprint-mdx": () => import("./../../../src/pages/about/news/{mdx.frontmatter__news_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/news/2023-12-05-cop28-tech-sprint.mdx" /* webpackChunkName: "component---src-pages-about-news-mdx-frontmatter-news-slug-tsx-content-file-path-src-content-mdx-news-2023-12-05-cop-28-tech-sprint-mdx" */),
  "component---src-pages-about-our-partners-tsx": () => import("./../../../src/pages/about/our-partners.tsx" /* webpackChunkName: "component---src-pages-about-our-partners-tsx" */),
  "component---src-pages-about-team-tsx": () => import("./../../../src/pages/about/team.tsx" /* webpackChunkName: "component---src-pages-about-team-tsx" */),
  "component---src-pages-case-studies-mdx-frontmatter-case-study-slug-tsx-content-file-path-src-content-mdx-case-studies-certificates-mdx": () => import("./../../../src/pages/case-studies/{mdx.frontmatter__case_study_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/case-studies/certificates.mdx" /* webpackChunkName: "component---src-pages-case-studies-mdx-frontmatter-case-study-slug-tsx-content-file-path-src-content-mdx-case-studies-certificates-mdx" */),
  "component---src-pages-case-studies-mdx-frontmatter-case-study-slug-tsx-content-file-path-src-content-mdx-case-studies-dual-mdx": () => import("./../../../src/pages/case-studies/{mdx.frontmatter__case_study_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/case-studies/dual.mdx" /* webpackChunkName: "component---src-pages-case-studies-mdx-frontmatter-case-study-slug-tsx-content-file-path-src-content-mdx-case-studies-dual-mdx" */),
  "component---src-pages-case-studies-mdx-frontmatter-case-study-slug-tsx-content-file-path-src-content-mdx-case-studies-purapipe-mdx": () => import("./../../../src/pages/case-studies/{mdx.frontmatter__case_study_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/case-studies/purapipe.mdx" /* webpackChunkName: "component---src-pages-case-studies-mdx-frontmatter-case-study-slug-tsx-content-file-path-src-content-mdx-case-studies-purapipe-mdx" */),
  "component---src-pages-case-studies-mdx-frontmatter-case-study-slug-tsx-content-file-path-src-content-mdx-company-mdx": () => import("./../../../src/pages/case-studies/{mdx.frontmatter__case_study_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/company.mdx" /* webpackChunkName: "component---src-pages-case-studies-mdx-frontmatter-case-study-slug-tsx-content-file-path-src-content-mdx-company-mdx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-partner-services-become-provider-tsx": () => import("./../../../src/pages/partner-services/become-provider.tsx" /* webpackChunkName: "component---src-pages-partner-services-become-provider-tsx" */),
  "component---src-pages-partner-services-current-providers-tsx": () => import("./../../../src/pages/partner-services/current-providers.tsx" /* webpackChunkName: "component---src-pages-partner-services-current-providers-tsx" */),
  "component---src-pages-platform-architecture-tsx": () => import("./../../../src/pages/platform/architecture.tsx" /* webpackChunkName: "component---src-pages-platform-architecture-tsx" */),
  "component---src-pages-platform-build-your-solution-tsx": () => import("./../../../src/pages/platform/build-your-solution.tsx" /* webpackChunkName: "component---src-pages-platform-build-your-solution-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-solutions-index-tsx": () => import("./../../../src/pages/solutions/index.tsx" /* webpackChunkName: "component---src-pages-solutions-index-tsx" */),
  "component---src-pages-solutions-mdx-frontmatter-solution-slug-tsx-content-file-path-src-content-mdx-company-mdx": () => import("./../../../src/pages/solutions/{mdx.frontmatter__solution_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/company.mdx" /* webpackChunkName: "component---src-pages-solutions-mdx-frontmatter-solution-slug-tsx-content-file-path-src-content-mdx-company-mdx" */),
  "component---src-pages-solutions-mdx-frontmatter-solution-slug-tsx-content-file-path-src-content-mdx-solutions-certificates-mdx": () => import("./../../../src/pages/solutions/{mdx.frontmatter__solution_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/solutions/certificates.mdx" /* webpackChunkName: "component---src-pages-solutions-mdx-frontmatter-solution-slug-tsx-content-file-path-src-content-mdx-solutions-certificates-mdx" */),
  "component---src-pages-solutions-mdx-frontmatter-solution-slug-tsx-content-file-path-src-content-mdx-solutions-corporate-mdx": () => import("./../../../src/pages/solutions/{mdx.frontmatter__solution_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/solutions/corporate.mdx" /* webpackChunkName: "component---src-pages-solutions-mdx-frontmatter-solution-slug-tsx-content-file-path-src-content-mdx-solutions-corporate-mdx" */),
  "component---src-pages-solutions-mdx-frontmatter-solution-slug-tsx-content-file-path-src-content-mdx-solutions-custody-mdx": () => import("./../../../src/pages/solutions/{mdx.frontmatter__solution_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/solutions/custody.mdx" /* webpackChunkName: "component---src-pages-solutions-mdx-frontmatter-solution-slug-tsx-content-file-path-src-content-mdx-solutions-custody-mdx" */),
  "component---src-pages-solutions-mdx-frontmatter-solution-slug-tsx-content-file-path-src-content-mdx-solutions-data-rooms-mdx": () => import("./../../../src/pages/solutions/{mdx.frontmatter__solution_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/solutions/data-rooms.mdx" /* webpackChunkName: "component---src-pages-solutions-mdx-frontmatter-solution-slug-tsx-content-file-path-src-content-mdx-solutions-data-rooms-mdx" */),
  "component---src-pages-solutions-mdx-frontmatter-solution-slug-tsx-content-file-path-src-content-mdx-solutions-partner-artworks-mdx": () => import("./../../../src/pages/solutions/{mdx.frontmatter__solution_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/solutions/partner/artworks.mdx" /* webpackChunkName: "component---src-pages-solutions-mdx-frontmatter-solution-slug-tsx-content-file-path-src-content-mdx-solutions-partner-artworks-mdx" */),
  "component---src-pages-solutions-mdx-frontmatter-solution-slug-tsx-content-file-path-src-content-mdx-solutions-partner-carbon-ledger-mdx": () => import("./../../../src/pages/solutions/{mdx.frontmatter__solution_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/solutions/partner/carbon-ledger.mdx" /* webpackChunkName: "component---src-pages-solutions-mdx-frontmatter-solution-slug-tsx-content-file-path-src-content-mdx-solutions-partner-carbon-ledger-mdx" */),
  "component---src-pages-solutions-mdx-frontmatter-solution-slug-tsx-content-file-path-src-content-mdx-solutions-twex-app-mdx": () => import("./../../../src/pages/solutions/{mdx.frontmatter__solution_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/solutions/twex-app.mdx" /* webpackChunkName: "component---src-pages-solutions-mdx-frontmatter-solution-slug-tsx-content-file-path-src-content-mdx-solutions-twex-app-mdx" */),
  "component---src-pages-use-cases-index-tsx": () => import("./../../../src/pages/use-cases/index.tsx" /* webpackChunkName: "component---src-pages-use-cases-index-tsx" */),
  "component---src-pages-use-cases-mdx-frontmatter-use-case-slug-tsx-content-file-path-src-content-mdx-company-mdx": () => import("./../../../src/pages/use-cases/{mdx.frontmatter__use_case_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/company.mdx" /* webpackChunkName: "component---src-pages-use-cases-mdx-frontmatter-use-case-slug-tsx-content-file-path-src-content-mdx-company-mdx" */),
  "component---src-pages-use-cases-mdx-frontmatter-use-case-slug-tsx-content-file-path-src-content-mdx-use-cases-carbon-management-carbon-offset-transparency-mdx": () => import("./../../../src/pages/use-cases/{mdx.frontmatter__use_case_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/use-cases/carbon-management/carbon-offset-transparency.mdx" /* webpackChunkName: "component---src-pages-use-cases-mdx-frontmatter-use-case-slug-tsx-content-file-path-src-content-mdx-use-cases-carbon-management-carbon-offset-transparency-mdx" */),
  "component---src-pages-use-cases-mdx-frontmatter-use-case-slug-tsx-content-file-path-src-content-mdx-use-cases-carbon-management-carbon-supply-chain-mdx": () => import("./../../../src/pages/use-cases/{mdx.frontmatter__use_case_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/use-cases/carbon-management/carbon-supply-chain.mdx" /* webpackChunkName: "component---src-pages-use-cases-mdx-frontmatter-use-case-slug-tsx-content-file-path-src-content-mdx-use-cases-carbon-management-carbon-supply-chain-mdx" */),
  "component---src-pages-use-cases-mdx-frontmatter-use-case-slug-tsx-content-file-path-src-content-mdx-use-cases-carbon-management-carbon-tax-mdx": () => import("./../../../src/pages/use-cases/{mdx.frontmatter__use_case_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/use-cases/carbon-management/carbon-tax.mdx" /* webpackChunkName: "component---src-pages-use-cases-mdx-frontmatter-use-case-slug-tsx-content-file-path-src-content-mdx-use-cases-carbon-management-carbon-tax-mdx" */),
  "component---src-pages-use-cases-mdx-frontmatter-use-case-slug-tsx-content-file-path-src-content-mdx-use-cases-carbon-management-emission-reporting-mdx": () => import("./../../../src/pages/use-cases/{mdx.frontmatter__use_case_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/use-cases/carbon-management/emission-reporting.mdx" /* webpackChunkName: "component---src-pages-use-cases-mdx-frontmatter-use-case-slug-tsx-content-file-path-src-content-mdx-use-cases-carbon-management-emission-reporting-mdx" */),
  "component---src-pages-use-cases-mdx-frontmatter-use-case-slug-tsx-content-file-path-src-content-mdx-use-cases-carbon-management-emission-rights-auctioning-mdx": () => import("./../../../src/pages/use-cases/{mdx.frontmatter__use_case_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/use-cases/carbon-management/emission-rights-auctioning.mdx" /* webpackChunkName: "component---src-pages-use-cases-mdx-frontmatter-use-case-slug-tsx-content-file-path-src-content-mdx-use-cases-carbon-management-emission-rights-auctioning-mdx" */),
  "component---src-pages-use-cases-mdx-frontmatter-use-case-slug-tsx-content-file-path-src-content-mdx-use-cases-digital-assets-artworks-mdx": () => import("./../../../src/pages/use-cases/{mdx.frontmatter__use_case_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/use-cases/digital-assets/artworks.mdx" /* webpackChunkName: "component---src-pages-use-cases-mdx-frontmatter-use-case-slug-tsx-content-file-path-src-content-mdx-use-cases-digital-assets-artworks-mdx" */),
  "component---src-pages-use-cases-mdx-frontmatter-use-case-slug-tsx-content-file-path-src-content-mdx-use-cases-digital-assets-corporate-governance-mdx": () => import("./../../../src/pages/use-cases/{mdx.frontmatter__use_case_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/use-cases/digital-assets/corporate-governance.mdx" /* webpackChunkName: "component---src-pages-use-cases-mdx-frontmatter-use-case-slug-tsx-content-file-path-src-content-mdx-use-cases-digital-assets-corporate-governance-mdx" */),
  "component---src-pages-use-cases-mdx-frontmatter-use-case-slug-tsx-content-file-path-src-content-mdx-use-cases-digital-assets-financing-mdx": () => import("./../../../src/pages/use-cases/{mdx.frontmatter__use_case_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/use-cases/digital-assets/financing.mdx" /* webpackChunkName: "component---src-pages-use-cases-mdx-frontmatter-use-case-slug-tsx-content-file-path-src-content-mdx-use-cases-digital-assets-financing-mdx" */),
  "component---src-pages-use-cases-mdx-frontmatter-use-case-slug-tsx-content-file-path-src-content-mdx-use-cases-digital-assets-investing-mdx": () => import("./../../../src/pages/use-cases/{mdx.frontmatter__use_case_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/use-cases/digital-assets/investing.mdx" /* webpackChunkName: "component---src-pages-use-cases-mdx-frontmatter-use-case-slug-tsx-content-file-path-src-content-mdx-use-cases-digital-assets-investing-mdx" */),
  "component---src-pages-use-cases-mdx-frontmatter-use-case-slug-tsx-content-file-path-src-content-mdx-use-cases-digital-assets-shared-mobile-property-mdx": () => import("./../../../src/pages/use-cases/{mdx.frontmatter__use_case_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/use-cases/digital-assets/shared-mobile-property.mdx" /* webpackChunkName: "component---src-pages-use-cases-mdx-frontmatter-use-case-slug-tsx-content-file-path-src-content-mdx-use-cases-digital-assets-shared-mobile-property-mdx" */),
  "component---src-pages-use-cases-mdx-frontmatter-use-case-slug-tsx-content-file-path-src-content-mdx-use-cases-digital-certificates-education-certificates-mdx": () => import("./../../../src/pages/use-cases/{mdx.frontmatter__use_case_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/use-cases/digital-certificates/education-certificates.mdx" /* webpackChunkName: "component---src-pages-use-cases-mdx-frontmatter-use-case-slug-tsx-content-file-path-src-content-mdx-use-cases-digital-certificates-education-certificates-mdx" */),
  "component---src-pages-use-cases-mdx-frontmatter-use-case-slug-tsx-content-file-path-src-content-mdx-use-cases-digital-certificates-health-certificates-mdx": () => import("./../../../src/pages/use-cases/{mdx.frontmatter__use_case_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/use-cases/digital-certificates/health-certificates.mdx" /* webpackChunkName: "component---src-pages-use-cases-mdx-frontmatter-use-case-slug-tsx-content-file-path-src-content-mdx-use-cases-digital-certificates-health-certificates-mdx" */),
  "component---src-pages-use-cases-mdx-frontmatter-use-case-slug-tsx-content-file-path-src-content-mdx-use-cases-governments-and-banks-bid-bonds-mdx": () => import("./../../../src/pages/use-cases/{mdx.frontmatter__use_case_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/use-cases/governments-and-banks/bid-bonds.mdx" /* webpackChunkName: "component---src-pages-use-cases-mdx-frontmatter-use-case-slug-tsx-content-file-path-src-content-mdx-use-cases-governments-and-banks-bid-bonds-mdx" */),
  "component---src-pages-use-cases-mdx-frontmatter-use-case-slug-tsx-content-file-path-src-content-mdx-use-cases-governments-and-banks-cbdc-mdx": () => import("./../../../src/pages/use-cases/{mdx.frontmatter__use_case_slug}.tsx?__contentFilePath=/builds/trustwise/trustwise.io/src/content/mdx/use-cases/governments-and-banks/cbdc.mdx" /* webpackChunkName: "component---src-pages-use-cases-mdx-frontmatter-use-case-slug-tsx-content-file-path-src-content-mdx-use-cases-governments-and-banks-cbdc-mdx" */)
}

